.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content {
  background-color: #F4F4F4;
  overflow: hidden;
  min-height: 720px;
  width: 538px;

  @media only screen and (max-width: 620px) {
    width: 400px;
  }

  @media only screen and (max-width: 460px) {
    width: 340px;
  }

  @media only screen and (max-width: 470px) {
    min-height: 550px;
  }

  & div div {
    overflow: visible;
    width: 100%;
  }
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root {
  position: relative;
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root > div {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
}

.PrivatePickersFadeTransitionGroup-root:first-child div {
  margin-left: 120px;
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root div > div[role=presentation] {
  font-size: 54px;
  width: 300px;
  //margin-left: 120px;
  text-transform: capitalize;
  color: #424242;

  @media only screen and (max-width: 630px) {
    width: 170px;
    margin-left: 70px;
    font-size: 36px;
  }
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root .MuiPickersArrowSwitcher-root {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin-top: -40px;
  margin-left: 0;

  @media only screen and (max-width: 630px) {
    width: 400px;
  }
  @media only screen and (max-width: 460px) {
    width: 340px;
  }
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root .MuiPickersArrowSwitcher-button {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  & span {
    background-color: transparent;
  }
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root .MuiPickersArrowSwitcher-root .MuiSvgIcon-root {
  font-size: 50px;

  @media only screen and (max-width: 630px) {
    margin-top: -17px;
  }
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-root .MuiPickersArrowSwitcher-root div {
  display: none;
}

.calendar {
  background-color: #F4F4F4;
}

.MuiPickerStaticWrapper-content .MuiCalendarPicker-root:nth-child(2) > div {
  justify-content: space-around;
}

.MuiPickerStaticWrapper-content .MuiCalendarPicker-root .MuiTypography-caption {
  font-weight: 700;
  color: black;
}

.MuiPickerStaticWrapper-content .MuiCalendarPicker-root div > div {
  justify-content: space-around;
}

.MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root button {
    font-size: 18px;
    margin: 18px;
    padding: 20px;

    @media only screen and (max-width: 630px) {
      padding: 0;
    }

    @media only screen and (max-width: 470px) {
      margin: 5px;
    }
}

.MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root div button:nth-child(6), .MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root div button:nth-child(7) {
  color: rgba(0, 0, 0, 0.87);
}

.MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root .MuiButtonBase-root.MuiPickersDay-root.active {
  &:after {
    content: '';
    position: absolute;
    top: 10%;
    left: 80%;
    border-radius: 100%;
    background-color: #3D8D4F;
    width: 10px;
    height: 10px;
  }
}

.MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root .MuiButtonBase-root.Mui-selected {
  background-color: rgba(61,141,79, .2);
  color: #424242;
}

.MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root .MuiButtonBase-root.Mui-disabled {
  background-color: transparent;
  color: #424242;

  &.MuiPickersDay-today {
    border: none;
  }
}
