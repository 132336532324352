@font-face {font-family: "Sen-Regular";
  src: url("assets/fonts/Sen-Regular.ttf");
}
@font-face {font-family: "Sen-Bold";
  src: url("assets/fonts/Sen-Bold.ttf");
}
@font-face {font-family: "Sen-ExtraBold";
  src: url("assets/fonts/Sen-ExtraBold.ttf");
}
@font-face {font-family: "Exo";
  src: url("assets/fonts/Exo-Bold.ttf");
}
body {
  margin: 0;
  font-family: 'Sen-Regular','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  line-height: 28px;
}